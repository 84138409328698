import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Text from 'components/ui/Text';
import styles from './Error.module.scss';

const ErrorPage = ({ statusCode, message }) => (
    <section className={styles.root}>
        <Head>
            <title>{`${statusCode}: ${message}`}</title>
        </Head>

        <Text className={styles.message} as="h3" theme="sectionHeadline">
            {statusCode}: {message}
        </Text>
    </section>
);

ErrorPage.propTypes = {
    statusCode: PropTypes.number,
    message: PropTypes.string,
};

export default ErrorPage;
