import React from 'react';
import Error from 'components/pages/Error';
import { fetchGlobals } from 'services/contentful';

export default function Custom404(props) {
    return <Error statusCode={404} message="Page not found" {...props} />;
}

export async function getStaticProps() {
    const globals = await fetchGlobals();

    return {
        props: { ...globals },
    };
}
